import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';

import PersonInfo from '../components/PersonInfo';
import Container from '../components/Container';
import ArticleBox from '../components/ArticleBox';

export default function IndexPage({ data: postdata, pageContext: { data } }) {
    const posts = postdata.allWpPost.nodes;
    console.log(postdata);

    return (
        <Container>
            <div className='d-flex flex-column'>
                <div
                    className='d-flex flex-column justify-content-end shadow-sm rounded overflow-hidden mb-5 w-100'
                    style={{
                        height: '350px',
                        backgroundImage:
                            'url(' +
                            data.acf_home.headerimage.image.mediaItemUrl +
                            ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <div
                        className='m-0 px-4 pb-3 pt-4 text-white'
                        style={{
                            background: `linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.45) 70%, rgba(0,0,0,0) 100%)`,
                        }}
                    >
                        <h3 className='m-0 '>
                            {data.acf_home.headerimage.title}
                        </h3>
                        <p className='m-0'>
                            {data.acf_home.headerimage.subtitle}
                        </p>
                    </div>
                </div>

                <div className='border-top border-bottom border-5 border-primary  bg-white rounded p-4 p-md-5 pb-md-3 mb-5  shadow w-100'>
                    <h2 className='text-center'>{data.acf_home.box.title}</h2>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data.acf_home.box.text,
                        }}
                    ></div>
                </div>

                <div className='row'>
                    {data.acf_home.infoboxes.map((box) => {
                        return (
                            <div className='col-12 col-md  mb-5'>
                                <div className='h-100  border-bottom border-5 border-primary  bg-white rounded p-4 pb-3  shadow w-100 d-flex flex-column align-items-center'>
                                    <img
                                        src={box.icon.mediaItemUrl}
                                        alt=''
                                        srcset=''
                                        className='mb-3'
                                        width='100'
                                        height='100'
                                    />
                                    <h4 className='text-center'>{box.title}</h4>
                                    <p className='text-center'>{box.text}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className='mt-4 '>
                    <h1 className='mb-3 text-center'>News</h1>
                    <div className='row mb-3'>
                        {posts.map((post, index) => {
                            const date = moment(post.date).format(
                                'D. MMMM YYYY'
                            );

                            return (
                                <div class='col-md-6'>
                                    <ArticleBox
                                        title={post.title}
                                        excerpt={post.content.replace(
                                            /<[^>]*>?/gm,
                                            ''
                                        )}
                                        imageURL={
                                            post.featuredImage?.node?.sourceUrl
                                        }
                                        date={date}
                                        url={
                                            post.acf_post?.newsletterPdf
                                                ?.mediaItemUrl || post.uri
                                        }
                                        category={post.categories.nodes[0].name}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export const query = graphql`
    {
        allWpPost(
            limit: 8
            filter: { status: { eq: "publish" } }
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                title
                date
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
                acf_post {
                    newsletterPdf {
                        id
                        mediaItemUrl
                    }
                }
                categories {
                    nodes {
                        name
                    }
                }
                link
                uri
                content
            }
        }
    }
`;
